import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoint } from "../breakpoints/breakpoints";
import Layout from "../components/layout";
import Home from "../icons/Home";

const ContentWrapper = styled.div`
background-color:  ${(props) => props.bgColor};
height: 100vh;

  padding: 20px 40px;

  @media ${breakpoint.mobileXL} {
    padding: 20px 100px;
  }

  @media ${breakpoint.tablet} {
    padding: 20px 150px;
  }
  @media ${breakpoint.laptop} {
    padding: 20px 200px;
  }

  & h1 {
    color: ${(props) => props.color1};
    margin: 50px 0;
  }
  & h2 {
    color: ${(props) => props.color2};
  }
`;

const StyledHome = styled(Home)`
  width: 30px;
  margin: 30px 0 30px -15px;
  fill: ${(props) => props.color1};
  cursor: pointer;

  & :hover {
    fill: ${(props) => props.color2};
  }
`;

const StyledLink = styled(Link)`
cursor: pointer;
text-decoration: underline;
& :hover {
    color: ${(props) => props.color};
    text-decoration: underline;
  }
`

function NotFoundPage({ data }) {
  if (!data) return null;
  const pageData = data.allPrismicLanding.edges[0].node.data;
  const button = pageData.body.find((slice) => (slice.slice_type === "button_on_hero"));

  return (
    <Layout>
      <ContentWrapper         
        bgColor={pageData.bgcolor1}
        color1={pageData.captioncolor}
        color2={pageData.captioncolor}>

      <Link to="/">
          <StyledHome
            color1={button.items[0].button_color}
            color2={button.items[0].hover_color}
          />
        </Link>
        <title>Not found</title>
        <h1>Seite nicht gefunden</h1>
        <p>
         Wir konnten die gewünschte Seite leider nicht finden.
        </p>
        <p>Hier gelangst du zurück zur <StyledLink to="/" color={pageData.captioncolor}>
          Startseite
        </StyledLink>.</p>
      </ContentWrapper>
    </Layout>
  );
}

export default NotFoundPage;

export const ErrorQuery = graphql`
  query ErrorQuery {
    allPrismicLanding {
      edges {
        node {
          data {
            bgcolor1
            captioncolor
            body {
              ... on PrismicLandingBodyButtonOnHero {
                id
                slice_type
                items {
                  button_color
                  hover_color
                  text_color
              }
            }
            }
        }
      }
    }
  }
  }
`;
